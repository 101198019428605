import { combineReducers } from '@reduxjs/toolkit';
import userReducers from '../slices/CustomSlices/userReducers';
import apiReducers from '../slices/CustomSlices/apiReducers';

const rootReducer = combineReducers({
  user: userReducers,
  api: apiReducers,
});

export default rootReducer;
